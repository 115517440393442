import PubSub from "@/lib/Pub";
export default {
	name: 'server1',
	data: () => {
		return {
			activeData: {
				title: '评估服务',
				data: [
					{
						img: require('@/assets/server3-1.png'),
						title: '评估人员',
						text: '由 CRC Manager、研究者、病人,同事对 CRC 的工作情况进行评分,综合得出CRC的能力水平',
					}, {
						img: require('@/assets/server3-2.png'),
						title: '评估原则',
						text: '遵守赫尔辛基宣言和GCP原则；客观、科学、公正原则；定量分析与定性分析相结合原则',
					}, {
						img: require('@/assets/server3-3.png'),
						title: '评估申请',
						text: '由 SMO 公司、药物临床试验机构向 C&C Club 平台申请评估 CRC 资格',
					}, {
						img: require('@/assets/server3-4.png'),
						title: '评估目的',
						text: '对CRC实际的能力水平进行了全方位、多维度评价。针对个人，可以通过平台的综合评价结果，获取相应的资质认定；针对医院和药企，可以通过平台自主匹配精准符合试验要求的CRC人员',
					}, {
						img: require('@/assets/server3-5.png'),
						title: '评估内容',
						text: '评估 CRC 在 GCP 基础理论/面试论述、沟通技巧、时间管理能力、CRF 填写、生物样本处理等方面的情况,给出主客观评分,设定能力水平星际',
					}, {
						img: require('@/assets/server3-6.png'),
						title: '评估方式',
						text: '项目相关参与者对CRC的工作表现给予多维度评价，量化为主观分值；信息化系统的数据自动计算与统计分析，量化为客观分值；最终对CRC的综合评价给予星级排名',
					},
				]
			},
		};
	},
	methods: {
		openTest() {
			if (!this.$root.userInfo.name) {
				PubSub.publish("showLoginEvent");
			} else {
				if (this.$root.userInfo.type == 4 && this.$root.userInfo.type && this.$root.userInfo.isPass != 1) {
					this.$message({
						showClose: true,
						message: "对不起，您无访问权限！",
						type: "warning"
					});
				} else {
					var h0 = window.location.protocol + "//" + window.location.host
					window.location.href = h0 + "/#/assess";
				}
			}
		},
	}
}
